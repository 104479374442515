<template>
  <div id="Chat">
    <div v-if="loaded" class="flex items-center justify-between">
        <div class="flex items-center">
            <span class="mr-1 font-medium">{{this.chatData.id}} - {{this.chatData.client.name}}</span>
        </div>
    </div>
    <div class="flex sm:items-baseline sm:space-x-4 mt-2 mb-4">
        <router-link to="/dashboard/chats/" class="transition ease-in-out duration-200 text-sm font-medium text-purple-600 hover:text-purple-500 block cursor-pointer"><span aria-hidden="true"> &larr;</span> Back to chat list</router-link>
    </div>
    <div class="min-h-full">
        <main class="pb-8">
        <div v-if="loaded" class="mx-auto max-w-3xl lg:max-w-7xl">
            <h1 class="sr-only">Page title</h1>
            <!-- Main 3 column grid -->
            <div class="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
            <!-- Left column -->
            <div class="grid grid-cols-1 gap-4 order-2 lg:order-none lg:col-span-2">
                <section aria-labelledby="section-1-title">
                <h2 class="sr-only" id="section-1-title">Section title</h2>
                <div class="overflow-hidden rounded-lg bg-white shadow">
                    <div>
                    <!-- Your content -->
                    <div class="overflow-hidden bg-white">
                    <div>
                      <div class="flex justify-between">
                        <div class="mx-auto min-w-full">
                          <div class="w-full flex justify-between bg-gray-100 text-gray-700 border-b border-gray-200 rounded-t-lg px-4 py-2">
                            <div class="flex items-center text-sm pr-2 sm:text-base">
                              <img class="inline-block border-gray-300 border h-8 w-8 rounded-full mr-2" :src="chatData.client.image" alt="" />
                              <span class="font-medium truncate">{{chatData.client.name}}</span>
                            </div>
                            <div class="flex items-center">
                              <XIcon @click="backToList()"  class="transition ease-in-out duration-200 h-5 w-5 hover:text-purple-500 cursor-pointer" aria-hidden="true" />
                            </div>
                          </div>
                          <div ref="chat" class="mx-auto min-h-mobileChat max-h-mobileChat lg:min-h-1 lg:max-h-chat min-w-full p-3 overflow-y-scroll rounded-md ">
                            <button v-if="chatData.messages.length < 1" @click="scrollToNewMessage()" type="button" class="transition ease-in-out duration-200 text-gray-500 hover:text-gray-700 cursor-pointer relative block w-full rounded-lg border-2 border-dashed border-gray-300 hover:border-gray-400 p-4 text-center">
                              <ChatAlt2Icon class="mx-auto h-8 w-8 opacity-60"  aria-hidden="true" />
                              <span class="mt-2 block text-sm font-normal">Start chat with customer.</span>
                            </button>
                            <div v-for="(message, index) in chatData.messages" :key="index">
                              <div v-if="message.customer" :class="{'flex max-w-full sm:max-w-chat': true, 'mt-4': index > 0}">
                                <div class="py-3 pl-4 pr-4 rounded-tl-lg rounded-bl-lg flex-shrink-0 bg-purple-200">
                                  <div>
                                    <img class="inline-block border-gray-300 border h-8 w-8 rounded-full" :src="message.image" alt="" />
                                  </div>
                                </div>
                                <div class="bg-purple-200 rounded-tr-lg rounded-br-lg pr-4 py-3">
                                  <h4 class="text-sm font-medium text-gray-500">{{message.name}}</h4>
                                  <p class="text-sm text-gray-900">{{message.message}}</p>
                                </div>
                                <div class="flex justify-start items-center"><span class="text-gray-300 text-xs ml-4">{{translateUnix(message.date)}}</span></div>
                              </div>
                              
                              <div v-if="!message.customer" :class="{'flex justify-end max-w-full sm:max-w-chat ml-auto': true, 'mt-4': index > 0}">
                                <div class="flex justify-start items-center"><span class="text-gray-300 text-xs mr-4">{{translateUnix(message.date)}}</span></div>
                                <div class="flex flex-row-reverse">
                                  <div class="py-3 pl-4 pr-4 rounded-tr-lg rounded-br-lg flex-shrink-0 bg-purple-50">
                                    <div>
                                      <img class="inline-block border-gray-300 border h-8 w-8 rounded-full" :src="message.image" alt="" />
                                    </div>
                                  </div>
                                  <div class="bg-purple-50 rounded-tl-lg rounded-bl-lg pl-4 py-3">
                                    <h4 class="text-sm text-right font-medium text-gray-500">{{message.name}}</h4>
                                    <p class="text-sm text-gray-900">{{message.message}}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Content goes here -->
                          <div class="flex items-start space-x-4 px-4 py-4 bg-gray-50 border-t border-gray-200">
                            <div class="flex-shrink-0">
                              <img class="inline-block h-10 w-10 border-gray-300 border rounded-full" :src="this.$store.state.user.photoURL" alt="" />
                            </div>
                            <div class="min-w-0 flex-1">
                              <form>
                                <div class="transition ease-in-out duration-300 border-b focus-within:rounded-md border-gray-200 hover:border-gray-50 focus-within:border-gray-50">
                                  <label for="comment" class="sr-only">Your message</label>
                                  <textarea id="chatInputDesktop" v-on:keypress.enter="sendMessage()" rows="3" v-model="this.chatMessage" class="transition ease-in-out duration-300 p-2 rounded-md hidden lg:block w-full bg-gray-50 resize-none border border-transparent pb-2 focus:border-purple-600 focus:ring-0 text-sm hover:bg-white focus:bg-white hover:border-gray-200" placeholder="Your message..." />
                                  <textarea id="chatInputMobile" v-on:keypress.enter="sendMessage()" rows="1" v-model="this.chatMessage" class="transition ease-in-out duration-300 p-2 rounded-md block lg:hidden w-full bg-gray-50 resize-none border border-transparent focus:border-purple-600 focus:ring-0 hover:bg-white focus:bg-white hover:border-gray-200 text-sm" placeholder="Your message..." />
                                </div>
                                <div class="flex justify-end pt-2">
                                  <!-- <div class="flex items-center space-x-5">
                                    <div class="flow-root">
                                      <button type="button" class="-m-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500">
                                        <PaperClipIcon class="h-6 w-6" aria-hidden="true" />
                                        <span class="sr-only">Attach a file</span>
                                      </button>
                                    </div>
                                  </div> -->
                                  <div class="flex-shrink-0">
                                    <button ref="button" @click.prevent="sendMessage()" :disabled="chatBlock" :class="{'inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2': true, 'bg-purple-600 hover:bg-purple-700': !chatBlock, 'bg-gray-500': chatBlock}">
                                      Send
                                      <PaperAirplaneIcon class="-mr-1 ml-2 h-4 w-4 transform rotate-90" aria-hidden="true" />
                                      </button>
                                  </div>
                                </div>
                                <div class="flex justify-end pt-2">
                                  <div v-if="chatError" class="inline-flex items-center text-xs font-medium text-red-600">
                                        {{this.chatErrorContent}}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                      test
                    </div> -->
                  </div>
                    </div>
                </div>
                </section>
            </div>

            <!-- Right column -->
            <div class="grid grid-cols-1 gap-4">
                <section aria-labelledby="section-2-title">
                <h2 class="sr-only" id="section-2-title">Attendant info</h2>
                <div class="overflow-hidden rounded-lg bg-white shadow">
                    <div>
                     <div :class="[this.$store.state.user.uid !== this.chatData.attendantId ? 'bg-red-50' : 'bg-green-50','rounded-md p-4']">
                        <div class="flex justify-center items-center">
                        <div class="flex-shrink-0 flex justify-center items-center">
                            <ExclamationIcon v-if="this.$store.state.user.uid !== this.chatData.attendantId" class="h-10 w-10 text-red-400" aria-hidden="true" />
                            <CheckCircleIcon v-else class="h-10 w-10 text-green-400" aria-hidden="true" />
                        </div>
                        <div class="ml-3 flex justify-center items-center">
                            <h3 :class="['text-xs font-medium', this.$store.state.user.uid !== this.chatData.attendantId ? 'text-red-800': 'text-green-800']">You are{{this.$store.state.user.uid !== this.chatData.attendantId ? ' not': ''}} an attendant of this complaint.</h3>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <h2 v-if="this.$store.state.user.uid !== this.chatData.attendantId" class="sr-only" id="section-2-title">Attendant of complaint</h2>
                <div v-if="this.$store.state.user.uid !== this.chatData.attendantId" class="overflow-hidden mt-4 rounded-lg bg-white shadow">
                    <div>
                        <div class="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                            <div class="flex w-full items-center justify-between space-x-6 p-6">
                                <div class="flex-1 truncate">
                                <div class="flex items-center space-x-3">
                                    <h3 class="truncate text-sm font-medium text-gray-900"> {{this.attendant.name}}</h3>
                                </div>
                                <p v-if="this.attendant.name !== 'DELETED'" class="mt-1 truncate text-sm text-gray-500">Attendant of this complaint</p>
                                <p v-if="this.attendant.name === 'DELETED'" class="mt-1 truncate text-sm text-gray-500">Attendant not exists</p>
                                </div>
                                <img class="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" :src="this.attendant.image" alt="" />
                            </div>
                            <div v-if="this.attendant.name !== 'DELETED'">
                                <div class="-mt-px flex divide-x divide-gray-200">
                                <div class="flex w-0 flex-1">
                                    <a :href="`mailto:${this.attendant.email}`" class="transition ease-in-out duration-200 cursor-pointer relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-white hover:bg-purple-400">
                                    <MailIcon class="h-5 w-5 opacity-60" aria-hidden="true" />
                                    <span class="ml-3">Email</span>
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                <h2 class="sr-only" id="section-2-title">Client info</h2>
                <div @click="visitUser()" class="transition ease-in-out duration-200 overflow-hidden mt-4 rounded-lg bg-white hover:bg-gray-50 shadow">
                    <div class="p-6 cursor-pointer">
                        <!-- <h1 id="message-heading" class="text-lg font-medium text-gray-900">Client information</h1> -->
                        <div class="relative flex space-x-3">
                            <div class="min-w-0 flex-1">
                                <span class="absolute inset-0" aria-hidden="true" />
                                <p class="text-sm font-medium text-gray-900">{{ this.chatData.client.name }}</p>
                                <div v-if="this.chatData.client.id.length > 0" class="flex mt-1 text-sm items-center text-gray-500">
                                    <IdentificationIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span>{{this.chatData.client.id}}</span>
                                </div>
                                <div v-if="this.chatData.client.email.length > 0" class="flex mt-1 text-sm items-center text-gray-500">
                                    <MailIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span>{{this.chatData.client.email}}</span>
                                </div>
                                <div v-if="this.chatData.client.phoneNum.length > 0" class="flex mt-1 text-sm items-center text-gray-500">
                                    <PhoneIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                    <span>{{this.chatData.client.phoneNum}}</span>
                                </div>
                            </div>
                            <div class="flex-shrink-0">
                                <img class="h-10 w-10 rounded-full" :src="this.chatData.client.image" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <h2 class="sr-only" id="section-2-title">Client info</h2>
                <div class="overflow-hidden mt-4 rounded-lg bg-white shadow">
                    <div class="p-6">
                        <h3 class="text-base font-medium text-gray-900">Chat options</h3>
                        <div class="mt-2 flex justify-between items-center">
                            <div class="flex flex-col">
                                <span class="text-sm font-medium text-gray-900">Read <span class="text-gray-400 text-xs font-normal">(for system users)</span></span>
                                <span class="text-xs font-medium text-gray-500">Mark chat as {{this.chatData.read ? 'not ': ''}}read</span>
                            </div>
                            <Switch @click="changeRead()" :class="[this.chatData.read ? 'bg-purple-600' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2']">
                                <span class="sr-only">Use setting</span>
                                <span :class="[this.chatData.read ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']">
                                <span :class="[this.chatData.read ? 'opacity-0 ease-out duration-100' : 'opacity-100 ease-in duration-200', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                    <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                                    <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </span>
                                <span :class="[this.chatData.read ? 'opacity-100 ease-in duration-200' : 'opacity-0 ease-out duration-100', 'absolute inset-0 flex h-full w-full items-center justify-center transition-opacity']" aria-hidden="true">
                                    <svg class="h-3 w-3 text-purple-600" fill="currentColor" viewBox="0 0 12 12">
                                    <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                                    </svg>
                                </span>
                                </span>
                            </Switch>
                        </div>
                    </div>
                </div>
                <h2 class="sr-only" id="section-2-title">Client info</h2>
                <div class="overflow-hidden mt-4 rounded-lg bg-white shadow">
                    <div>
                        <button @click="goToComplaint(this.chatData.id)" type="button" class="transition ease-in-out duration-200 inline-flex items-center justify-center w-full  border border-transparent bg-purple-600 px-6 py-3 text-sm md:text-sm xl:text-base font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Manage customer complaint</button>
                    </div>
                </div>
                </section>
            </div>
            </div>
        </div>
        </main>
    </div>
  </div>
</template>

<script>
    import {db, firebase} from "@/firebase/gfbconf.js";
    import moment from 'moment';
    import { 
  XIcon,
  PaperAirplaneIcon,
  ChatAlt2Icon,
  ExclamationIcon,
  CheckCircleIcon,
  IdentificationIcon,
  MailIcon,
  PhoneIcon
  } from '@heroicons/vue/outline'
  import { Switch } from '@headlessui/vue'
    export default {
        name: "Chat",
        components: {
        XIcon,
        PaperAirplaneIcon,
        ChatAlt2Icon,
        ExclamationIcon,
        CheckCircleIcon,
        IdentificationIcon,
        MailIcon,
        PhoneIcon,
        Switch
        },
        data: function () {
            return {
                attendant: {
                    name: "",
                    email: "",
                    image: ""
                },
                ownReadStatusChange: false,
                switchBlock: false,
                currentChatId: "",
                chatData: {},
                chatBlock: false,
                chatMessage: "",
                chatError: false,
                chatErrorContent: "",
                loaded: false,
                dbListener: null
            }
        },
        mounted(){
            this.currentChatId = this.$route.query.id
            this.downloadChat()
        },
        unmounted(){
          this.dbListener()
        },
        async created()
        {
        },
        methods: {
            visitUser(){
              this.$router.push(`/dashboard/clients/view/${this.chatData.client.id}`)
            },
            changeRead(){
                if(this.switchBlock === false){
                    this.ownReadStatusChange = true
                    this.switchBlock = true
                    if(this.chatData.read === true){
                        this.chatData.read = false
                        this.markAsNotRead()
                    }else{
                        this.chatData.read = true
                        this.markAsRead()
                    }
                }
            },
            getAttendantById(id)
            {
                let attendantName = "DELETED";
                let attendantEmail = "DELETED";
                let attendantImage = "https://avatars.dicebear.com/api/initials/D%3F%3F.svg";
                for(let i=0; i<this.$store.state.systemData.users.length;i++)
                {
                if(this.$store.state.systemData.users[i].id.toString() === id.toString())
                {
                    attendantName = this.$store.state.systemData.users[i].displayName;
                    attendantEmail = this.$store.state.systemData.users[i].email;
                    attendantImage = `https://avatars.dicebear.com/api/initials/${encodeURIComponent(attendantName)}.svg`
                }
                }
                this.attendant.name =  attendantName
                this.attendant.email =  attendantEmail
                this.attendant.image =  attendantImage
            },
            async sendMessage(){
                const date = moment().toDate()
                let message = {
                    customer: false,
                    date: date,
                    image: this.$store.state.user.photoURL,
                    message: this.chatMessage,
                    name: `${this.$store.state.userData.name} ${this.$store.state.userData.surname}`
                }
                if(this.chatMessage.length > 0){
                this.chatBlock = true

                await db.collection('Systems').doc(this.$store.state.userData.system.id).collection('Chats').doc(this.currentChatId.toString()).update({
                    'messages':  firebase.firestore.FieldValue.arrayUnion(message),
                });
                    this.chatMessage = ""
                    this.chatBlock = false
                    this.chatError = false
                    this.chatErrorContent = ""
                }else{
                this.chatError = true
                this.chatErrorContent = "Your message has no content."
                setTimeout(() => {
                    this.chatMessage = ""
                }, 10)
                }
            },
            backToList(){
                this.$router.push('/dashboard/chats')
            },
            goToComplaint(id){
                this.$router.push(`/dashboard/complaints/edit?id=${id}`)
            },
            scrollToNewMessage(){
                this.$refs["button"].scrollIntoView({ behavior: "smooth" })
                // document.getElementById("chatInput").focus()
                if(screen.width < 1024){
                document.getElementById("chatInputMobile").focus()
                }else{
                document.getElementById("chatInputDesktop").focus()
                }
            },
            translateUnix(unix){
                let date = moment.unix(unix.seconds).calendar()
                return date
            },
            async downloadChat(){
                this.dbListener = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").where('id', "==", this.currentChatId.toString())
                .onSnapshot((querySnapshot) => {
                    this.chatData = {};
                    this.chatData = querySnapshot.docs[0].data()
                    this.scrollChatToBottom();
                    this.getAttendantById(this.chatData.attendantId)
                    if(this.chatData.read === false && this.ownReadStatusChange === false){
                        this.markAsRead()
                    }
                    this.loaded = true
                });
            },
            async markAsRead(){
                await db.collection('Systems').doc(this.$store.state.userData.system.id).collection('Chats').doc(this.currentChatId).update({
                    'read': true,
                });
                this.switchBlock = false
            },
            async markAsNotRead(){
                await db.collection('Systems').doc(this.$store.state.userData.system.id).collection('Chats').doc(this.currentChatId).update({
                    'read': false,
                });
                this.switchBlock = false
            },
            scrollChatToBottom(){
                setTimeout(() => {
                    let chat = this.$refs.chat
                    chat.scrollTo({top: 99999999999, behavior: 'smooth'})
                }, 50)
            }
        }
    };
</script>
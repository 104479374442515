<template>
  <div id="ChatList">
    <div>
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <Listbox as="div" v-model="currentCardIndx">
        <!-- <ListboxLabel class="block text-sm font-medium text-gray-700"> Month </ListboxLabel> -->
        <div class="mt-1 relative">
          <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
            <span class="flex items-center truncate">{{ this.tabs[currentCardIndx].name }} <span  class="text-xs font-normal ml-1" v-show="this.tabs[currentCardIndx].optionalWord.length>0">({{this.tabs[currentCardIndx].optionalWord}})</span></span>
            <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>

          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
              <ListboxOption @click.prevent="changeCard(tabNdx)" as="template" v-for="(tab,tabNdx) in tabs" :key="tab.name" :value="tabNdx" v-slot="{ active, selected }">
                <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{ tab.name }} <span  class="text-xs font-normal ml-1" v-show="tab.optionalWord.length>0">({{tab.optionalWord}})</span></span>

                  <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>
            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      </div>
      <div class="hidden sm:block select-none">
        <div class="border-b border-gray-200">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <div v-for="(tab,tabNdx) in tabs" @click.prevent="changeCard(tabNdx)" :key="tab.name" :class="[tab.current ? 'border-purple-500 text-purple-600 cursor-pointer' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 cursor-pointer', 'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer']" :aria-current="tab.current ? 'page' : undefined">
              {{ tab.name }} <span  class="text-xs font-normal" v-show="tab.optionalWord.length>0">({{tab.optionalWord}})</span>
            </div>
          </nav>
        </div>
      </div>
    </div>

    

    <div class="mt-6 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0" v-if="!queryLoading">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                    <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-gray-50">
                <tr>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Status</span>
                  </th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Customer</th>
                  <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">Last message</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Attendant</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Complaint ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Reply status</th>
                  <th scope="col" class="relative py-3.5 pr-4 sm:pr-3">
                    <span class="sr-only">Chat</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <tr @click="openChat(chat.id)" v-for="(chat) in chats" :key="chat.id" class="transition ease-in-out duration-200 bg-white hover:bg-gray-100 cursor-pointer">
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                    <span class="flex ml-auto flex-shrink-0 items-center justify-center">
                        <span :class="{'h-1.5 w-1.5 rounded-full': true, 'bg-purple-500': !chat.read, 'border border-gray-500 bg-white': chat.read}" aria-hidden="true" />
                    </span>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-3">
                    <div class="flex items-center">
                      <div class="h-10 w-10 flex-shrink-0">
                        <img class="h-10 w-10 rounded-full" :src="chat.client.image" alt="" />
                      </div>
                      <div class="ml-4">
                        <div class="max-w-1">
                            <div class="font-medium text-gray-900 truncate"> {{chat.client.name}} </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-3">
                    <div class="flex items-center m-0 p-0">
                      <div class="w-72">
                        <div :class="{'text-gray-600 truncate': true, 'opacity-50': chat.read}" v-if="chat.messages.length > 0"> <span :class="[chat.messages[chat.messages.length-1].customer ? 'text-purple-500' : 'text-blue-500']">{{chat.messages[chat.messages.length-1].customer ? "Customer:" : "Attendant:"}}</span> {{chat.messages[chat.messages.length-1].message}}</div>
                        <div class="text-gray-300 truncate" v-if="chat.messages.length === 0">No messages yet</div>
                      </div>
                    </div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"> {{substringText(getAttendantById(chat.attendantId))}}</td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <div class="text-purple-700">{{chat.id}}</div>
                  </td>

                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    <span v-if="chat.messages.length > 0 && chat.messages[chat.messages.length-1].customer" class="inline-flex rounded-full bg-orange-100 px-2 text-xs font-semibold leading-5 text-orange-800">Not replied</span>
                    <span v-if="chat.messages.length > 0 && !chat.messages[chat.messages.length-1].customer" class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">Replied</span>
                    <span v-if="chat.messages.length === 0" class="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">No messages</span>
                  </td>

                  <td class="whitespace-nowrap py-4 pl-1 pr-4 text-sm">
                    <span class="flex ml-auto flex-shrink-0 items-center justify-center">
                        <ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </td>
                  
                </tr>
              </tbody>
            </table>
                    
                </div>
            </div>
        </div>
    </div>
    <div class="flex items-center justify-center mt-4" v-if="queryLimitReached === false && !queryLoading && chats.length !== 0">
        <button type="button" @click.prevent="loadMore" class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Load more..</button>
    </div> 
    <EmptyState class="mt-2 mb-2" v-if="chats.length === 0"></EmptyState>
    <Spinner v-if="queryLoading"></Spinner>
    
  </div>
</template>

<script>
    import {db} from "@/firebase/gfbconf.js";
    import { ChevronRightIcon} from '@heroicons/vue/outline';
    import EmptyState from '../components/EmptyState.vue';
    import Spinner  from '@/components/loadingSpinner.vue';
    import moment from 'moment';
    import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
    import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
    export default {
        name: "ChatList",
        components: {
            EmptyState,
            Spinner,
            ChevronRightIcon,
            Listbox, ListboxButton, ListboxOption, ListboxOptions,
            CheckIcon, SelectorIcon
        },
        data: function () {
            return {
                currentCardIndx: 0,
                query: null,
                queryLoading: false,
                currentQueryType: "all",
                chats: [],
                loading: false,
                queryLimitReached: false,
                queryLimit: 25,
                dbListener: null,
                lastLoaded: null,
                tabs: [
                  { name: 'All', optionalWord: "", current: true, queryType: "all" },{ name: 'My chats', optionalWord: ``, current: false, queryType: "forMe" },{ name: 'Unread', optionalWord: "", current: false, queryType: "unRead"} ,{ name: 'Today', optionalWord: "Created", current: false, queryType: "todayCreated" }
                ]
            }
        },
        async created()
        {
          await this.getAllChats();
        },
        methods: {
          openChat(id){
            this.$router.push(`/dashboard/chats/view?id=${id}`);
          },
          continueListening()
          {
              this.queryLimit = this.queryLimit + 10;
              this.dbListener = this.query.limit(this.queryLimit)
              .onSnapshot((querySnapshot) => {
                  this.chats = [];
                  if(querySnapshot.docs.length<this.queryLimit)
                  {
                      this.queryLimitReached = true;
                  }
                  else
                  {
                      this.queryLimitReached = false;
                  }
                  querySnapshot.forEach((doc) => {
                    this.chats.push(doc.data());
                    this.lastLoaded = doc.data().lastCustomerMessage._seconds;
                  });
              });
          },
          loadMore()
          {
            this.dbListener();
            this.continueListening();
          },
          substringText(name)
          {
            if(name.length>18)
            {
              name = name.substring(0,18) + ".."
            }
            return name;
          },
          changeCard(tabNdx)
          {
            for(let i=0; i<this.tabs.length;i++)
            {
              this.tabs[i].current = false;
            }
            this.tabs[tabNdx].current = true;
            this.currentCardIndx = tabNdx
            this.dbListener();
            this.currentQueryType = this.tabs[tabNdx].queryType;
            this.getAllChats();
          },
          getAttendantById(id)
          {
            let attendantName = "DELETED";
            for(let i=0; i<this.$store.state.systemData.users.length;i++)
            {
              if(this.$store.state.systemData.users[i].id.toString() === id.toString())
              {
                attendantName = this.$store.state.systemData.users[i].displayName;
              }
            }
            return attendantName
          },
          async getAllChats()
          {
            this.query = null;
            this.queryLimit = 25;
            if(this.dbListener !== null)
            {
              this.dbListener();
              this.dbListener = null;
            }
            this.queryLoading = true;
            let whatIsToday = moment().format("YYYY-MM-DD").toString()
            // let yesterdayUnix = moment().subtract(1,'days').unix();
            switch(this.currentQueryType)
            {
              case 'all':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").orderBy("lastCustomerMessage","desc");
                break;
              case 'todayCreated':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").where('createdDate', "==", whatIsToday).orderBy("lastCustomerMessage","desc");
                break;
              case 'forMe':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").where("attendantId", "==", this.$store.state.user.uid).orderBy("lastCustomerMessage","desc");
                break;
              case 'unRead':
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").where("read", "==", false).orderBy("lastCustomerMessage","desc");
                break;
              default:
                this.query = db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Chats").orderBy("lastCustomerMessage","desc");
                break;
            }

            this.dbListener = this.query.limit(this.queryLimit)
            .onSnapshot((querySnapshot) => {
                this.chats = [];
                if(querySnapshot.docs.length<this.queryLimit)
                {
                    this.queryLimitReached = true;
                }
                else
                {
                    this.queryLimitReached = false;
                }
                querySnapshot.forEach((doc) => {

                    this.chats.push(doc.data());
                    this.lastLoaded = doc.data().lastCustomerMessage._seconds;
                });
                this.queryLoading = false;
            });
          }
        }
    };
</script>
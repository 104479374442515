<template>
    <div>
        <h3 class="text-xl mt-4 leading-6 font-medium text-gray-900">Today's stats</h3>
        <dl class="mt-2 grid grid-cols-1 rounded-xl bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">

        <div class="px-4 py-5 " v-if="statsRendered">
            <dt class="text-xs xl:text-sm  font-normal text-gray-900">
                Created complaints
            </dt>
            <dd class="mt-1 flex justify-between items-center md:block xl:flex">
            <div class="flex flex-col xl:flex-row items-baseline xl:items-center text-base  font-semibold text-purple-600">
                {{this.today.created}}
                <span class="xl:ml-1 text-xs xl:mt-1 font-normal text-gray-500"> Yesterday: {{yesterday.created}} </span>
            </div>

            <div :class="[difference.created > 0 ? 'bg-green-100 text-green-800' : difference.created === 0 ? 'bg-yellow-100 text-yellow-800' :'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-xs xl:text-sm font-medium md:mt-2 xl:mt-0']">
                <ArrowSmUpIcon v-if="difference.created > 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-green-500" aria-hidden="true" />
                <ArrowSmDownIcon v-if="difference.created < 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-red-500" aria-hidden="true" />
                <SwitchVerticalIcon  v-if="difference.created === 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-yellow-500" aria-hidden="true"></SwitchVerticalIcon>
                {{difference.created > 0 ? difference.created : difference.createdFixed}}
            </div>
            </dd>
        </div>
        <div class="px-4 py-5 " v-if="statsRendered">
            <dt class="text-xs xl:text-sm  font-normal text-gray-900">
                Finished complaints
            </dt>
            <dd class="mt-1 flex justify-between items-center md:block xl:flex">
            <div class="flex flex-col xl:flex-row items-baseline xl:items-center text-base  font-semibold text-purple-600">
                {{this.today.finished}}
                <span class="xl:ml-1 text-xs xl:mt-1 font-normal text-gray-500"> Yesterday: {{yesterday.finished}} </span>
            </div>

            <div :class="[difference.finished > 0 ? 'bg-green-100 text-green-800' : difference.finished === 0 ? 'bg-yellow-100 text-yellow-800' :'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-xs xl:text-sm font-medium md:mt-2 xl:mt-0']">
                <ArrowSmUpIcon v-if="difference.finished > 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-green-500" aria-hidden="true" />
                <ArrowSmDownIcon v-if="difference.finished < 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-red-500" aria-hidden="true" />
                <SwitchVerticalIcon  v-if="difference.finished === 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-yellow-500" aria-hidden="true"></SwitchVerticalIcon>
                {{difference.finished > 0 ? difference.finished : difference.finishedFixed}}
            </div>
            </dd>
        </div>
        <div class="px-4 py-5 " v-if="statsRendered">
            <dt class="text-xs xl:text-sm  font-normal text-gray-900">
                Total value of complained products
            </dt>
            <dd class="mt-1 flex justify-between items-center md:block xl:flex">
            <div class="flex flex-col xl:flex-row items-baseline xl:items-center text-base  font-semibold text-purple-600">
                ${{this.today.complaintsWorth.toFixed(2)}}
                <span class="xl:ml-1 text-xs xl:mt-1 font-normal text-gray-500"> Yesterday: ${{yesterday.complaintsWorth.toFixed(2)}} </span>
            </div>

            <div :class="[difference.complaintsWorth > 0 ? 'bg-green-100 text-green-800' : difference.complaintsWorth === 0 ? 'bg-yellow-100 text-yellow-800' :'bg-red-100 text-red-800', 'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-xs xl:text-sm font-medium md:mt-2 xl:mt-0']">
                <ArrowSmUpIcon v-if="difference.complaintsWorth > 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-green-500" aria-hidden="true" />
                <ArrowSmDownIcon v-if="difference.complaintsWorth < 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-red-500" aria-hidden="true" />
                <SwitchVerticalIcon  v-if="difference.complaintsWorth === 0" class="-ml-1 mr-0.5 flex-shrink-0 self-center h-4 w-4 xl:h-5 xl:w-5 text-yellow-500" aria-hidden="true"></SwitchVerticalIcon>
                ${{difference.complaintsWorth > 0 ? difference.complaintsWorth.toFixed(2) : difference.complaintsWorthFixed.toFixed(2)}}
            </div>
            </dd>
        </div>
        </dl>
        <div class="mt-3 w-full flex justify-center" v-if="this.$store.state.userData.permissions.analytics">
             <span @click="goToStats()" class="cursor-pointer transition ease-in-out duration-150 text-sm  text-purple-600 hover:text-purple-800">- Check all available stats -</span>
        <!-- <button type="button" @click="this.$router.push({ path: '/dashboard/analytics' })" class="transition ease-in-out duration-150 inline-flex w-full items-center justify-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700">
            <PresentationChartLineIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Check all available stats
        </button> -->
        </div>
    </div>
</template>

<script>

import {db} from '@/firebase/gfbconf.js';
import moment from 'moment';
import { ArrowSmDownIcon, ArrowSmUpIcon, SwitchVerticalIcon } from '@heroicons/vue/solid'

    export default {
        name: "todayStats",
        components: {ArrowSmDownIcon, ArrowSmUpIcon, SwitchVerticalIcon},
        data: function () {
            return {
                stats: [
                { name: 'Total Subscribers', stat: '71,897', previousStat: '70,946', change: '12%', changeType: 'increase' },
                { name: 'Avg. Open Rate', stat: '58.16%', previousStat: '56.14%', change: '2.02%', changeType: 'increase' },
                { name: 'Avg. Click Rate', stat: '24.57%', previousStat: '28.62%', change: '4.05%', changeType: 'decrease' },
                ],
              statsRendered: false,
              statsPicker: {
                year: 0,
                month: "January",
                day: 0,
              },
              today: {
                finished: 0,
                created: 0,
                complaintsWorth: 0
              },
              yesterday: {
                finished: 0,
                created: 0,
                complaintsWorth: 0
              },
              difference:{
                finished: 0,
                finishedFixed: 0,
                created: 0,
                createdFixed: 0,
                complaintsWorth: 0,
                complaintsWorthFixed: 0
              },
              allStats: {}
            }
        },
        methods: {
            goToStats()
                {
                    this.$store.commit('setGlobalLoader',{
                    show: true,
                    head: "Loading your statistics..",
                    subheader: `Do not close this tab.`,
                    });
                    setTimeout(() => {
                    this.$router.push("/dashboard/analytics")
                    },350) 
                },
          async downloadStats(){
            const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc((this.statsPicker.year).toString()).get();
            if(result.data() !== undefined){
            this.allStats = result.data()
            }else{
                this.allStats = null
            }
            this.pickStats();
          },
        async pickStats(){
            this.statsRendered = false
            if(this.allStats !== null){
                if(this.allStats[this.statsPicker.month] !== undefined && this.allStats[this.statsPicker.month].days[this.statsPicker.day] !== undefined){
                this.today.finished = this.allStats[this.statsPicker.month].days[this.statsPicker.day].global.complaints.finished
                this.today.created = this.allStats[this.statsPicker.month].days[this.statsPicker.day].global.complaints.created
                this.today.complaintsWorth = this.allStats[this.statsPicker.month].days[this.statsPicker.day].global.complaints.complaintsWorth
                }else{
                    this.today.finished = 0
                    this.today.created = 0
                    this.today.complaintsWorth = 0
                }
                if(this.statsPicker.day === 1 && this.statsPicker.month === "January"){
                    const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc((this.statsPicker.year-1).toString()).get();
                    const resultData = result.data()
                    if(resultData.December !== undefined && resultData.December.days[31] !== undefined){
                        this.yesterday.finished = resultData.December.days[31].global.complaints.finished
                        this.yesterday.created = resultData.December.days[31].global.complaints.created
                        this.yesterday.complaintsWorth = resultData.December.days[31].global.complaints.complaintsWorth
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }else{
                        this.yesterday.finished = 0
                        this.yesterday.created = 0
                        this.yesterday.complaintsWorth = 0
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }
                }

                if(this.statsPicker.day === 1 && this.statsPicker.month !== "January"){
                    const months = [{name: "January", id: "01"}, {name: "February", id: "02"}, {name: "March", id: "03"}, {name: "April", id: "04"}, {name: "May", id: "05"}, {name: "June", id: "06"}, {name: "July", id: "07"}, {name: "August", id: "08"}, {name: "September", id: "09"}, {name: "October", id: "10"}, {name: "November", id: "11"}, {name: "December", id: "12"}]
                    let indxOfMonthBefore = 0
                    for(let i=0; i<months.length; i++){
                        if(months[i].name === this.statsPicker.month){
                            indxOfMonthBefore = i-1
                        }
                    }
                    let lastDayOfMonth = moment(`${this.statsPicker.year}-${months[indxOfMonthBefore].id}`, "YYYY-MM").daysInMonth()
                    if(this.allStats[months[indxOfMonthBefore].name] !== undefined && this.allStats[months[indxOfMonthBefore].name].days[lastDayOfMonth] !== undefined){
                        this.yesterday.finished = this.allStats[months[indxOfMonthBefore].name].days[lastDayOfMonth].global.complaints.finished
                        this.yesterday.created = this.allStats[months[indxOfMonthBefore].name].days[lastDayOfMonth].global.complaints.created
                        this.yesterday.complaintsWorth = this.allStats[months[indxOfMonthBefore].name].days[lastDayOfMonth].global.complaints.complaintsWorth
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }else{
                        this.yesterday.finished = 0
                        this.yesterday.created = 0
                        this.yesterday.complaintsWorth = 0
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }
                }
                
                if(this.statsPicker.day > 1){
                    if(this.allStats[this.statsPicker.month] !== undefined && this.allStats[this.statsPicker.month].days[this.statsPicker.day-1] !== undefined){
                        this.yesterday.finished = this.allStats[this.statsPicker.month].days[this.statsPicker.day-1].global.complaints.finished
                        this.yesterday.created = this.allStats[this.statsPicker.month].days[this.statsPicker.day-1].global.complaints.created
                        this.yesterday.complaintsWorth = this.allStats[this.statsPicker.month].days[this.statsPicker.day-1].global.complaints.complaintsWorth
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }else{
                        this.yesterday.finished = 0
                        this.yesterday.created = 0
                        this.yesterday.complaintsWorth = 0
                        this.difference.finished = this.today.finished - this.yesterday.finished
                        this.difference.created = this.today.created - this.yesterday.created
                        this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                        if(this.difference.finished < 0){
                            this.difference.finishedFixed = this.difference.finished * -1
                        }else{
                            this.difference.finishedFixed = this.difference.finished
                        }
                        if(this.difference.created < 0){
                            this.difference.createdFixed = this.difference.created * -1
                        }else{
                            this.difference.createdFixed = this.difference.created
                        }
                        if(this.difference.complaintsWorth < 0){
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                        }else{
                            this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                        }
                    }
                }
            }else{
                this.today.finished = 0
                this.today.created = 0
                this.today.complaintsWorth = 0
                this.yesterday.finished = 0
                this.yesterday.created = 0
                this.yesterday.complaintsWorth = 0
                this.difference.finished = this.today.finished - this.yesterday.finished
                this.difference.created = this.today.created - this.yesterday.created
                this.difference.complaintsWorth = this.today.complaintsWorth - this.yesterday.complaintsWorth
                if(this.difference.finished < 0){
                    this.difference.finishedFixed = this.difference.finished * -1
                }else{
                    this.difference.finishedFixed = this.difference.finished
                }
                if(this.difference.created < 0){
                    this.difference.createdFixed = this.difference.created * -1
                }else{
                    this.difference.createdFixed = this.difference.created
                }
                if(this.difference.complaintsWorth < 0){
                    this.difference.complaintsWorthFixed = this.difference.complaintsWorth * -1
                }else{
                    this.difference.complaintsWorthFixed = this.difference.complaintsWorth
                }
            }
            setTimeout(() => {
                this.$store.commit("resetGlobalLoader");
            }, 200)
            this.statsRendered = true
            // this.copyToDatabase()
          }
        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
          this.statsPicker.year = moment().year()
          this.statsPicker.month = moment().format('MMMM');
          this.statsPicker.day = parseInt(moment().format('D'));
          this.downloadStats();
          this.$store.commit('setGlobalLoader',{
            show: true,
            head: "Loading your statistics..",
            subheader: `Do not close this tab.`,
           });
        }
    };
</script>
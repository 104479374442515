<template>
    <div v-if="!emptyState">
      <div class="border-b border-gray-200 mt-4 mb-4 w-auto ">
      <div class="sm:flex sm:items-baseline">
        <div class="mt-4 sm:mt-0">
          <nav class="-mb-px flex space-x-4">
            <div @click="changeType('year')" :class="{'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': this.statsPicker.type !== 'year', 'cursor-default border-purple-500 text-purple-600': this.statsPicker.type === 'year', 'whitespace-nowrap pb-3 px-1 border-b-2 font-medium transition ease-in-out duration-150': true}">
              Yearly stats
            </div>
            <div @click="changeType('month')" :class="{'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': this.statsPicker.type !== 'month', 'cursor-default border-purple-500 text-purple-600': this.statsPicker.type === 'month', 'whitespace-nowrap pb-3 px-1 border-b-2 font-medium transition ease-in-out duration-150': true}">
              Monthly stats
            </div>
            <div @click="changeType('day')" :class="{'cursor-pointer border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300': this.statsPicker.type !== 'day', 'cursor-default border-purple-500 text-purple-600 ml-auto': this.statsPicker.type === 'day', 'whitespace-nowrap pb-3 px-2 border-b-2 font-medium transition ease-in-out duration-150': true}">
              Daily stats
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div v-if="this.statsRendered === true">
      <dl :class="{'mt-2 mb-2 grid grid-cols-1 gap-5 sm:grid-cols-3 lg:grid-cols-5': true}">
        <Listbox as="div" v-model="statsPicker.year">
        <ListboxLabel class="block text-sm font-medium text-gray-700"> Year </ListboxLabel>
          <div class="mt-1 relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
              <span class="block truncate">{{ statsPicker.year }}</span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                <ListboxOption @click="pickStats()" as="template" v-for="year in availableStats.years" :key="year" :value="year" v-slot="{ active, selected }">
                  <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'select-none relative py-2 pl-3 pr-9 cursor-pointer']">
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                      {{ year }}
                    </span>

                    <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>

        <Listbox as="div" v-if="statsPicker.type === 'day' || statsPicker.type === 'month'">
          <ListboxLabel class="block text-sm font-medium text-gray-700"> Month </ListboxLabel>
          <div class="mt-1 relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
              <span class="block truncate">{{ statsPicker.month }}</span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                <ListboxOption @click="changePicker(month,'month')" as="template" v-for="month in picker.months" :key="month" :value="month" v-slot="{ active }">
                  <li :class="[active && month.available ? 'text-white bg-purple-600' : 'text-gray-900', month.available ? 'bg-white cursor-pointer' : 'bg-gray-50 cursor-not-allowed', 'select-none relative py-2 pl-3 pr-9']">
                    <span :class="[month.name === this.statsPicker.month ? 'font-semibold' : 'font-normal', !month.available ? 'text-gray-500' : '', 'block truncate']">
                      {{ month.name }}
                    </span>
                    <span v-if="month.name === this.statsPicker.month" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                    <span v-if="!month.available" :class="['absolute text-gray-400 inset-y-0 right-0 flex items-center pr-4']">
                      <span class="mr-2 text-xs text-gray-200">No data</span>
                      <LockClosedIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>

        <Listbox as="div" v-if="statsPicker.type === 'day'">
        <ListboxLabel class="block text-sm font-medium text-gray-700"> Day </ListboxLabel>
          <div class="mt-1 relative">
            <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
              <span class="block truncate"><span v-if="statsPicker.day < 10">0</span>{{ statsPicker.day }}</span>
              <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
              <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                <ListboxOption @click="changePicker(day,'day')" as="template" v-for="day in picker.days" :key="day" :value="day" v-slot="{ active}">
                  <li :class="[active && day.available ? 'text-white bg-purple-600' : 'text-gray-900', day.available ? 'bg-white cursor-pointer' : 'bg-gray-50 cursor-not-allowed', ' select-none relative py-2 pl-3 pr-9']">
                    <span :class="[day.num === this.statsPicker.day ? 'font-semibold' : 'font-normal', !day.available ? 'text-gray-500' : '', 'block truncate']">
                      <span v-if="day.num < 10">0</span>{{ day.num }}
                    </span>
                    <span v-if="day.num === this.statsPicker.day" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                    <span v-if="!day.available" :class="['absolute text-gray-400 inset-y-0 right-0 flex items-center pr-4']">
                      <span class="mr-2 text-xs text-gray-200">No data</span>
                      <LockClosedIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </dl>
      <div class="mb-4 mt-3 flex items-center align-middle text-xs text-gray-400">
            <InformationCircleIcon class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            If you are not able to pick any date, that mean there is no valuable data.
      </div>

      
      <h3 class="text-xl leading-6 font-medium text-gray-900">Complaints</h3>
      <div class="mt-2">
        <div class="sm:hidden">
          <Listbox as="div" v-model="complaintsShowStats">
            <ListboxLabel class="block text-sm font-medium text-gray-700"> Pick analytics </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="block truncate">{{ complaintsShowStats }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" value="Created" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Created
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Status" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Status
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Products" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Products
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Chargebacks info" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Chargebacks info
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Delivery info" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Delivery info
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Priority stats" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Priority stats
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="All" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          All
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
        </div>
        <div class="hidden sm:block">
          <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            <div @click="this.complaintsShowStats = 'Created'" :class="{'text-gray-900': complaintsShowStats === 'Created', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Created', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 rounded-l-lg flex items-center justify-center': true}">
              <span class="truncate"> Created </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Created', 'bg-transparent': complaintsShowStats !== 'Created', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'Status'" :class="{'text-gray-900': complaintsShowStats === 'Status', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Status', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Status </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Status', 'bg-transparent': complaintsShowStats !== 'Status', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'Products'" :class="{'text-gray-900': complaintsShowStats === 'Products', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Products', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Products </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Products', 'bg-transparent': complaintsShowStats !== 'Products', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'Chargebacks info'" :class="{'text-gray-900': complaintsShowStats === 'Chargebacks info', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Chargebacks info', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Chargebacks info </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Chargebacks info', 'bg-transparent': complaintsShowStats !== 'Chargebacks info', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'Delivery info'" :class="{'text-gray-900': complaintsShowStats === 'Delivery info', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Delivery info', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Delivery info </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Delivery info', 'bg-transparent': complaintsShowStats !== 'Delivery info', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'Priority stats'" :class="{'text-gray-900': complaintsShowStats === 'Priority stats', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'Priority stats', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Priority stats </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'Priority stats', 'bg-transparent': complaintsShowStats !== 'Priority stats', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.complaintsShowStats = 'All'" :class="{'text-gray-900': complaintsShowStats === 'All', 'text-gray-500 hover:text-gray-700': complaintsShowStats !== 'All', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 rounded-r-lg flex items-center justify-center': true}">
              <span class="truncate"> All </span>
              <span aria-hidden="true" :class="{'bg-purple-500': complaintsShowStats === 'All', 'bg-transparent': complaintsShowStats !== 'All', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>
          </nav>
        </div>
      </div>
      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-2 text-sm leading-6 font-medium text-gray-600">Created</h4>
      <dl v-if="this.complaintsShowStats === 'Created' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3': true, 'mt-4': this.complaintsShowStats === 'Created'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Created complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.created}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Complaints added manually
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.addedManually}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Complaints added by client
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.addedByClient}}
          </dd>
        </div>
      </dl>
      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-4 text-sm leading-6 font-medium text-gray-500">Status</h4>
      <dl v-if="this.complaintsShowStats === 'Status' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3': true, 'mt-4': this.complaintsShowStats === 'Status'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Finished complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.finished}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Active complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.active}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Declined products in complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.declined}}
          </dd>
        </div>
        
      </dl>

      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-4 text-sm leading-6 font-medium text-gray-500">Products</h4>
      <dl v-if="this.complaintsShowStats === 'Products' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3': true, 'mt-4': this.complaintsShowStats === 'Products'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of cases in complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.casesAmount}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of complained products
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.complainedProductsQuantity}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Total value of complained products
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            ${{currentStats.global.complaints.complaintsWorth.toFixed(2)}}
          </dd>
        </div>
      </dl>

      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-4 text-sm leading-6 font-medium text-gray-500">Chargebacks info</h4>
      <dl v-if="this.complaintsShowStats === 'Chargebacks info' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2': true, 'mt-4': this.complaintsShowStats === 'Chargebacks info'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of chargebacks
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.chargeback.quantity}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Total value of chargebacks
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            ${{currentStats.global.complaints.chargeback.worth.toFixed(2)}}
          </dd>
        </div>
      </dl>

      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-4 text-sm leading-6 font-medium text-gray-500">Delivery info</h4>
      <dl v-if="this.complaintsShowStats === 'Delivery info' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-3': true, 'mt-4': this.complaintsShowStats === 'Delivery info'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of delivery
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.deliveryData.delivery}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of delivery and pickups
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.deliveryData.deliveryAndPickup}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of pickups
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.deliveryData.pickup}}
          </dd>
        </div>
      </dl>

      <h4 v-if="this.complaintsShowStats === 'All'" class="mt-4 text-sm leading-6 font-medium text-gray-500">Priority stats</h4>
      <dl v-if="this.complaintsShowStats === 'Priority stats' || this.complaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-5': true, 'mt-4': this.complaintsShowStats === 'Priority stats'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Level 1
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.priorityStats.Level1}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Level 2
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.priorityStats.Level2}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Level 3
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.priorityStats.Level3}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Level 4
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.priorityStats.Level4}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Level 5
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.complaints.priorityStats.Level5}}
          </dd>
        </div>
      </dl>

      <h3 class="text-xl mt-8 leading-6 font-medium text-gray-900">Complained products</h3>

      <div class="mt-2">
        <div class="sm:hidden">
          <Listbox as="div" v-model="productComplaintsShowStats">
            <ListboxLabel class="block text-sm font-medium text-gray-700"> Pick analytics </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="block truncate">{{ productComplaintsShowStats }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" value="Most frequently complained product" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Most frequently complained product
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="Most loss-making product" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          Most loss-making product
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                    <ListboxOption as="template" value="All" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          All
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
        </div>
        <div class="hidden sm:block">
          <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            <div @click="this.productComplaintsShowStats = 'Most frequently complained product'" :class="{'text-gray-900': productComplaintsShowStats === 'Most frequently complained product', 'text-gray-500 hover:text-gray-700': productComplaintsShowStats !== 'Most frequently complained product', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 rounded-l-lg flex items-center justify-center': true}">
              <span class="truncate"> Most frequently complained product </span>
              <span aria-hidden="true" :class="{'bg-purple-500': productComplaintsShowStats === 'Most frequently complained product', 'bg-transparent': productComplaintsShowStats !== 'Most frequently complained product', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.productComplaintsShowStats = 'Most loss-making product'" :class="{'text-gray-900': productComplaintsShowStats === 'Most loss-making product', 'text-gray-500 hover:text-gray-700': productComplaintsShowStats !== 'Most loss-making product', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 flex items-center justify-center': true}">
              <span class="truncate"> Most loss-making product </span>
              <span aria-hidden="true" :class="{'bg-purple-500': productComplaintsShowStats === 'Most loss-making product', 'bg-transparent': productComplaintsShowStats !== 'Most loss-making product', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>

            <div @click="this.productComplaintsShowStats = 'All'" :class="{'text-gray-900': productComplaintsShowStats === 'All', 'text-gray-500 hover:text-gray-700': productComplaintsShowStats !== 'All', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 rounded-r-lg flex items-center justify-center': true}">
              <span class="truncate"> All </span>
              <span aria-hidden="true" :class="{'bg-purple-500': productComplaintsShowStats === 'All', 'bg-transparent': productComplaintsShowStats !== 'All', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>
          </nav>
        </div>
      </div>

      <h4 v-if="this.productComplaintsShowStats === 'All'" class="mt-2 text-sm leading-6 font-medium text-gray-500">Most frequently complained product</h4>
      <dl v-if="this.productComplaintsShowStats === 'Most frequently complained product' || this.productComplaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1': true, 'mt-4': this.productComplaintsShowStats === 'Most frequently complained product'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Product name
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{ currentStats.global.products.length === 0 || currentStats.global.products[this.mostComplainedIndx].amount === 0 ? 'No product' : currentStats.global.products[this.mostComplainedIndx].name}}
          </dd>
        </div>
      </dl>
      <dl v-if="this.productComplaintsShowStats === 'Most frequently complained product' || this.productComplaintsShowStats === 'All'" class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.products.length === 0 ? 0 : currentStats.global.products[this.mostComplainedIndx].amount}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Total cost of complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            ${{currentStats.global.products.length === 0 ? '0.00' : (currentStats.global.products[this.mostComplainedIndx].cost).toFixed(2)}}
          </dd>
        </div>
      </dl>

      <h4 v-if="this.productComplaintsShowStats === 'All'" class="mt-2 text-sm leading-6 font-medium text-gray-500">Most loss-making product</h4>
      <dl v-if="this.productComplaintsShowStats === 'Most loss-making product' || this.productComplaintsShowStats === 'All'" :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1': true, 'mt-4': this.productComplaintsShowStats === 'Most loss-making product'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Product name
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.products.length === 0 || currentStats.global.products[this.lossMakerIndx].amount === 0 ? 'No product' : currentStats.global.products[this.lossMakerIndx].name}}
          </dd>
        </div>
      </dl>
      <dl v-if="this.productComplaintsShowStats === 'Most loss-making product' || this.productComplaintsShowStats === 'All'" class="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Amount of complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.products.length === 0 ? 0 : currentStats.global.products[this.lossMakerIndx].amount}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Total cost of complaints
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            ${{currentStats.global.products.length === 0 ? '0.00' : (currentStats.global.products[this.lossMakerIndx].cost).toFixed(2)}}
          </dd>
        </div>
      </dl>

      <h3 class="text-xl mt-8 leading-6 font-medium text-gray-900">Product creator</h3>
      <div class="mt-2">
        <div class="sm:hidden">
          <Listbox as="div" v-model="productCreatorShowStats">
            <ListboxLabel class="block text-sm font-medium text-gray-700"> Pick analytics </ListboxLabel>
              <div class="mt-1 relative">
                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                  <span class="block truncate">{{ productCreatorShowStats }}</span>
                  <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </ListboxButton>

                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                  <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                    <ListboxOption as="template" value="All" v-slot="{ active, selected }">
                      <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'cursor-default select-none relative py-2 pl-3 pr-9']">
                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                          All
                        </span>

                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                          <CheckIcon class="h-5 w-5" aria-hidden="true" />
                        </span>
                      </li>
                    </ListboxOption>
                  </ListboxOptions>
                </transition>
              </div>
            </Listbox>
        </div>
        <div class="hidden sm:block">
          <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            <div @click="this.productCreatorShowStats = 'All'" :class="{'text-gray-900': productCreatorShowStats === 'All', 'text-gray-500 hover:text-gray-700': productCreatorShowStats !== 'All', 'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 cursor-pointer transition ease-in-out duration-150 rounded-l-lg rounded-r-lg flex items-center justify-center': true}">
              <span class="truncate"> All </span>
              <span aria-hidden="true" :class="{'bg-purple-500': productCreatorShowStats === 'All', 'bg-transparent': productCreatorShowStats !== 'All', 'absolute inset-x-0 bottom-0 h-0.5 transition ease-in-out duration-150': true}" />
            </div>
          </nav>
        </div>
      </div>
      <dl :class="{'mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2': true, 'mt-4': this.productCreatorShowStats === 'All'}">
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Created products
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.productCreator.added}}
          </dd>
        </div>
        <div class="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-5">
          <dt class="text-sm font-medium text-gray-500 truncate">
            Deleted products
          </dt>
          <dd class="mt-1 text-xl font-semibold text-gray-900">
            {{currentStats.global.productCreator.deleted}}
          </dd>
        </div>
      </dl>
      
    </div>
  </div>
  <div v-if="emptyState" type="button" class="select-none relative block w-full border-2 border-purple-200 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    <SparklesIcon class="mx-auto h-12 w-12 text-purple-200"></SparklesIcon>
    <span class="mt-2 block text-sm font-medium text-purple-300"> Nothing here.. </span>
    <span class="block text-xs text-purple-300"> Start using the system to generate analytics  </span>
  </div>
</template>

<script>

import {db} from '@/firebase/gfbconf.js';
import moment from 'moment';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon} from '@heroicons/vue/solid'
import { InformationCircleIcon, LockClosedIcon, SparklesIcon } from '@heroicons/vue/outline'
    export default {
        name: "Stats",
        components: {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,SparklesIcon, LockClosedIcon, CheckIcon, SelectorIcon, InformationCircleIcon },
        data: function () {
            return {
              emptyState: false,
              picker:{
                years: [],
                months: [],
                days: [],
              },
              complaintsShowStats: "Created",
              productComplaintsShowStats: "Most frequently complained product",
              productCreatorShowStats: "All",
              statsRendered: false,
              statsPicker: {
                year: 0,
                month: "January",
                day: 11,
                type: "month"
              },
              allStats: [],
              currentStats: {},
              mostComplainedIndx: 0,
              lossMakerIndx: 0,
              availableStats:{
                years: [],
                months: [],
                days: []
              },
              unavailableStats:{
                years: [],
                months: [],
                days: []
              },
              tabs: [
                { name: 'My Account', href: '#', current: true },
                { name: 'Company', href: '#', current: false },
                { name: 'Team Members', href: '#', current: false },
                { name: 'Billing', href: '#', current: false },
                { name: 'Billing', href: '#', current: false },
              ]
            }
        },
        methods: {
          changePicker(data, type){
            if(type === 'day'){
              if(data.available){
                this.statsPicker.day = data.num
                this.pickStats()
              }
            }
            if(type === 'month'){
              if(data.available){
                this.statsPicker.month = data.name
                this.pickStats()
              }
            }
          },
          async copyToDatabase(){
            // this.allStats.BACKUP = this.allStats[0].December.days[31].global
            // await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").doc("TUTAJROK").set(this.allStats[1]);
          },
          async downloadStats(){
            const result = await db.collection("Systems").doc(this.$store.state.userData.system.id).collection("Analytics").get();
            for(let i=0; i<result.docs.length; i++){
              let item = result.docs[i].data();
              this.allStats.push(item)
            }
            if(result.docs.length > 0){
            this.pickStats();
            }else{
              this.emptyState = true
            }

            setTimeout(() => {
              this.$store.commit("resetGlobalLoader");
            },200)
            // this.copyToDatabase()
          },
          changeType(type){
            if(type !== this.statsPicker.type){
            this.statsRendered = false
              this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Loading your statistics..",
                subheader: `Do not close this tab.`,
            });
            this.statsPicker.type = type
            setTimeout(()=>
            {
              this.pickStats()
              this.$store.commit("resetGlobalLoader");
              this.statsRendered = true
            },300);
            }
          },
          pickStats(){
            this.statsRendered = false
            // this.statsPicker.month = "January"
            this.availableStats = {
                years: [],
                months: [],
                days: []
              }
            this.unavailableStats = {
                years: [],
                months: [],
                days: []
              }
              this.picker = {
                years: [],
                months: [],
                days: [],
              }
              // months to check
            const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            const monthsWithId = [{name: "January", id: "01"}, {name: "February", id: "02"}, {name: "March", id: "03"}, {name: "April", id: "04"}, {name: "May", id: "05"}, {name: "June", id: "06"}, {name: "July", id: "07"}, {name: "August", id: "08"}, {name: "September", id: "09"}, {name: "October", id: "10"}, {name: "November", id: "11"}, {name: "December", id: "12"}]
            let indxOfMonth = 0
            for(let i=0; i<monthsWithId.length; i++){
                if(monthsWithId[i].name === this.statsPicker.month){
                    indxOfMonth = i
                }
            }
            let amountOfDays = moment(`${this.statsPicker.year}-${monthsWithId[indxOfMonth].id}`, "YYYY-MM").daysInMonth()
            if(this.statsPicker.type === "year"){
              for(let i=0; i<this.allStats.length; i++){
                this.availableStats.years.push(this.allStats[i].year)
                if(this.allStats[i].year === this.statsPicker.year){
                  this.currentStats = this.allStats[i]
                  let moneyIndex = 0
                  let moneyCost = 0
                  let amountIndex = 0
                  let highestAmount = 0
                  let totalAmountOfProducts = 0
                  for(let y=0; y<this.currentStats.global.products.length; y++){
                    let currentProduct = this.currentStats.global.products[y]
                    let currentProductCost = 0
                    let currentProductAmount = 0
                    for(let c = 0; c < currentProduct.costs.length; c++) {
                      currentProductCost += currentProduct.costs[c].cost * currentProduct.costs[c].amount
                      currentProductAmount += currentProduct.costs[c].amount
                      totalAmountOfProducts += currentProduct.costs[c].amount
                    }
                    currentProduct.cost = currentProductCost
                    currentProduct.amount = currentProductAmount
                    if(currentProductCost > moneyCost){
                      moneyCost = currentProductCost
                      moneyIndex = y
                    }
                    if(currentProduct.amount > highestAmount){
                      amountIndex = y
                      highestAmount = currentProduct.amount
                    }
                  }
                  this.currentStats.global.complaints.complainedProductsQuantity = totalAmountOfProducts
                  this.mostComplainedIndx = amountIndex
                  this.lossMakerIndx = moneyIndex
                }
              }
            }
            if(this.statsPicker.type === "month"){
              for(let i=0; i<this.allStats.length; i++){
                this.availableStats.years.push(this.allStats[i].year)
                if(parseInt(this.allStats[i].year)  === this.statsPicker.year){
                  for(let z=0; z<months.length; z++){
                    if(this.allStats[i][months[z]] !== undefined){
                      this.availableStats.months.push(months[z])
                    }else{
                      this.unavailableStats.months.push(months[z])
                    }
                  }
                  //Checking if unavailable sats for current picked month, if not available picking last month from picked year
                  for(let u=0; u<this.unavailableStats.months.length; u++){
                    if(this.unavailableStats.months[u] === this.statsPicker.month){
                      this.statsPicker.month = this.availableStats.months[this.availableStats.months.length-1]
                    }
                  }
                  this.currentStats = this.allStats[i][this.statsPicker.month]
                  let moneyIndex = 0
                  let moneyCost = 0
                  let amountIndex = 0
                  let highestAmount = 0
                  let totalAmountOfProducts = 0
                  for(let y=0; y<this.currentStats.global.products.length; y++){
                    let currentProduct = this.currentStats.global.products[y]
                    let currentProductCost = 0
                    let currentProductAmount = 0
                    for(let c = 0; c < currentProduct.costs.length; c++) {
                      currentProductCost += currentProduct.costs[c].cost * currentProduct.costs[c].amount
                      currentProductAmount += currentProduct.costs[c].amount
                      totalAmountOfProducts += currentProduct.costs[c].amount
                    }
                    currentProduct.cost = currentProductCost
                    currentProduct.amount = currentProductAmount
                    if(currentProductCost > moneyCost){
                      moneyCost = currentProductCost
                      moneyIndex = y
                    }
                    if(currentProduct.amount > highestAmount){
                      amountIndex = y
                      highestAmount = currentProduct.amount
                    }
                  }
                  this.currentStats.global.complaints.complainedProductsQuantity = totalAmountOfProducts
                  this.mostComplainedIndx =  amountIndex
                  this.lossMakerIndx = moneyIndex
                }
              }
            }
            if(this.statsPicker.type === "day"){
              for(let i=0; i<this.allStats.length; i++){
                this.availableStats.years.push(this.allStats[i].year)
                if(this.allStats[i].year === this.statsPicker.year){
                  for(let z=0; z<months.length; z++){
                    if(this.allStats[i][months[z]] !== undefined){
                      this.availableStats.months.push(months[z])
                    }else{
                      this.unavailableStats.months.push(months[z])
                    }
                  }
                  //Checking if unavailable sats for current picked month, if not available picking last month from picked year
                  for(let u=0; u<this.unavailableStats.months.length; u++){
                    if(this.unavailableStats.months[u] === this.statsPicker.month){
                      this.statsPicker.month = this.availableStats.months[this.availableStats.months.length-1]
                    }
                  }

                  // const oneDate = moment(`${this.$store.state.systemData.created.asText}`, 'YYYY-MM-DD');
                  // const startDay = this.$store.state.systemData.created.dayNum
                  // const startMonth = oneDate.format('MMMM');
                  // const startYear = this.$store.state.systemData.created.year
                  // for(let x=1; x<amountOfDays+1; x++){
                  //   let currentDay = {
                  //     num: x,
                  //     available: false
                  //   }
                  //   if(this.allStats[i][this.statsPicker.month].days[x] !== undefined){
                  //     currentDay.available = true
                  //     this.availableStats.days.push(x)
                  //   }else{
                  //     this.unavailableStats.days.push(x)
                  //   }
                    // 
                  //   if(this.statsPicker.month === moment().format('MMMM') && this.statsPicker.year === moment().year() ){
                  //     if(this.statsPicker.month === startMonth && this.statsPicker.year === startYear){
                  //       if(x < parseInt(moment().format('D'))+1 && x+1 > startDay){
                  //         this.picker.days.push(currentDay)
                  //       }
                  //     }else{
                  //       if(x < parseInt(moment().format('D'))+1){
                  //         this.picker.days.push(currentDay)
                  //       }
                  //     }
                  //   }else{
                  //     if(this.statsPicker.month === startMonth && this.statsPicker.year === startYear){
                  //       if(x+1 > startDay){
                  //         this.picker.days.push(currentDay)
                  //       }
                  //     }else{
                  //       this.picker.days.push(currentDay)
                  //     }
                  //   }
                      
                  //   }
                  //Checking if unavailable sats for current picked day, if not available picking last day from picked month
                  for(let x=1; x<amountOfDays+1; x++){
                    let currentDay = {
                      num: x,
                      available: false
                    }
                    if(this.allStats[i][this.statsPicker.month].days[x] !== undefined){
                      currentDay.available = true
                      this.availableStats.days.push(x)
                    }else{
                      this.unavailableStats.days.push(x)
                    }
                  }
                  if(this.statsPicker.day > amountOfDays){
                    this.statsPicker.day = amountOfDays
                  }
                  for(let ud=0; ud<this.unavailableStats.days.length; ud++){
                    if(this.unavailableStats.days[ud] === this.statsPicker.day){
                      this.statsPicker.day = this.availableStats.days[this.availableStats.days.length-1]
                    }
                  }

                  this.currentStats = this.allStats[i][this.statsPicker.month].days[this.statsPicker.day]
                  let moneyIndex = 0
                  let moneyCost = 0
                  let amountIndex = 0
                  let highestAmount = 0
                  let totalAmountOfProducts = 0
                  for(let y=0; y<this.currentStats.global.products.length; y++){
                    let currentProduct = this.currentStats.global.products[y]
                    let currentProductCost = 0
                    let currentProductAmount = 0
                    for(let c = 0; c < currentProduct.costs.length; c++) {
                      currentProductCost += currentProduct.costs[c].cost * currentProduct.costs[c].amount
                      currentProductAmount += currentProduct.costs[c].amount
                      totalAmountOfProducts += currentProduct.costs[c].amount
                    }
                    currentProduct.cost = currentProductCost
                    currentProduct.amount = currentProductAmount
                    if(currentProductCost > moneyCost){
                      moneyCost = currentProductCost
                      moneyIndex = y
                    }
                    if(currentProduct.amount > highestAmount){
                      amountIndex = y
                      highestAmount = currentProduct.amount
                    }
                  }
                  this.currentStats.global.complaints.complainedProductsQuantity = totalAmountOfProducts
                  this.mostComplainedIndx =  amountIndex
                  this.lossMakerIndx = moneyIndex
                }
              }
            }
             for(let i=0; i<this.allStats.length; i++){
              if(this.allStats[i].year === this.statsPicker.year){
              const oneDate = moment(`${this.$store.state.systemData.created.asText}`, 'YYYY-MM-DD');
                  const startDay = this.$store.state.systemData.created.dayNum
                  const startMonth = oneDate.format('MMMM');
                  const startMonthId = parseInt(oneDate.format('MM'));
                  const startYear = this.$store.state.systemData.created.year
                  if(this.statsPicker.year === startYear){
                    for(let x=0; x<monthsWithId.length; x++){
                      let month = monthsWithId[x]
                      let currentMonth = {
                        name: month.name,
                        available: false
                      }
                      if(parseInt(month.id) >= startMonthId){
                        if(this.allStats[i][month.name] !== undefined){
                          currentMonth.available = true
                          if(this.statsPicker.year === moment().year()){
                            if(parseInt(month.id) <= moment().month() + 1){
                              this.picker.months.push(currentMonth)
                            }
                          }else{
                            this.picker.months.push(currentMonth)
                          }
                        }else{
                          if(this.statsPicker.year === moment().year()){
                            if(parseInt(month.id) <= moment().month() + 1){
                              this.picker.months.push(currentMonth)
                            }
                          }else{
                            this.picker.months.push(currentMonth)
                          }
                        }
                      }
                    }
                  }else{
                    
                    if(this.statsPicker.year === moment().year()){
                      for(let x=0; x<monthsWithId.length; x++){
                        let month = monthsWithId[x]
                        let currentMonth = {
                          name: month.name,
                          available: false
                        }
                        if(this.allStats[i][month.name] !== undefined){
                          currentMonth.available = true
                          if(parseInt(month.id) <= moment().month() + 1){
                              this.picker.months.push(currentMonth)
                          }
                        }else{
                          if(parseInt(month.id) <= moment().month() + 1){
                              this.picker.months.push(currentMonth)
                          }
                        }
                      }
                    }else{
                      for(let x=0; x<monthsWithId.length; x++){
                        let month = monthsWithId[x]
                        let currentMonth = {
                          name: month.name,
                          available: false
                        }
                        if(this.allStats[i][month.name] !== undefined){
                          currentMonth.available = true
                          this.picker.months.push(currentMonth)
                        }else{
                          this.picker.months.push(currentMonth)
                        }
                      }
                    }
                  }

                  for(let x=1; x<amountOfDays+1; x++){
                    let currentDay = {
                      num: x,
                      available: false
                    }
                    if(this.allStats[i][this.statsPicker.month].days[x] !== undefined){
                      currentDay.available = true
                      this.availableStats.days.push(x)
                    }else{
                      this.unavailableStats.days.push(x)
                    }
                    
                    if(this.statsPicker.month === moment().format('MMMM') && this.statsPicker.year === moment().year() ){
                      if(this.statsPicker.month === startMonth && this.statsPicker.year === startYear){
                        if(x < parseInt(moment().format('D'))+1 && x+1 > startDay){
                          this.picker.days.push(currentDay)
                        }
                      }else{
                        if(x < parseInt(moment().format('D'))+1){
                          this.picker.days.push(currentDay)
                        }
                      }
                    }else{
                      if(this.statsPicker.month === startMonth && this.statsPicker.year === startYear){
                        if(x+1 > startDay){
                          this.picker.days.push(currentDay)
                        }
                      }else{
                        this.picker.days.push(currentDay)
                      }
                    }
                      
                    }
             }
            }
            this.statsRendered = true
            // this.copyToDatabase()
          }
        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
          this.statsPicker.year = moment().year()
          this.statsPicker.month = moment().format('MMMM');
          this.statsPicker.day = parseInt(moment().format('D'));
          this.downloadStats();
          this.$store.commit('setGlobalLoader',{
              show: true,
              head: "Loading your statistics..",
              subheader: `Do not close this tab.`,
          });
        }
    };
</script>
<template>
    <div id="Permissions" class="mt-2">
            <div class="flex flex-col">
                <h2 class="text-lg font-medium text-gray-900">Manage your team permissions</h2>
                <p class="text-sm text-gray-500">Manage your system user permissions to some options.</p>
            </div>
            <div v-if="systemList.length > 0" class="mt-8 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
                <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8 lg:-mr-6 xl:-mr-8">
                    <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
                        <table class="min-w-full table-fixed divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                            <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-36 text-left text-xs font-semibold text-gray-900 sm:pl-6">Name</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900">Analytics</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900">Team Tasks</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900">Team Management</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900">Customer Portal</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-400 pl-4 border-l border-black border-opacity-5 bg-gray-100 select-none">Edit:</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900 bg-gray-100">Complaints</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900 bg-gray-100">Customers</th>
                            <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900 bg-gray-100">Products</th>
                            <th v-if="$store.state.userData.systemCreator" scope="col" class="whitespace-nowrap px-2 py-3.5 text-center text-xs font-semibold text-gray-900 bg-gray-100">Permissions</th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white" v-if="systemList.length > 0">
                            <tr v-for="(client,clIndx) in systemList" :key="clIndx" :class="{'bg-gray-200 opacity-50': client.blocked}">
                            <td :class="['pl-4 text-gray-500 sm:pl-6 whitespace-nowrap py-4 pr-3 text-sm font-medium flex items-center', client.permissions.editingPermissions ? 'text-purple-600' : 'text-black']  ">
                                <UserIcon v-if="!client.blocked" class="h-4 w-4 mr-6 text-purple-500" /> <LockClosedIcon v-if="client.blocked" class="h-4 w-4 mr-6 text-gray-800" /> <span class="truncate">{{ client.name.length > 15 ? client.name.substring(0,15) + '...' : client.name}} {{ client.surname.length > 15 ? client.surname.substring(0,15) + '...' : client.surname}}</span>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.analytics" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.teamManagement" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.teamTasks" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-900">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.customerPortal" :id="client.id" />
                                </div>
                            </td>
                            <td></td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.editingComplaints" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.editingCustomers" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.editingProducts" :id="client.id" />
                                </div>
                            </td>
                            <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500" v-if="$store.state.userData.systemCreator">
                                <div class="w-full flex justify-center">
                                    <input :disabled="client.blocked" type="checkbox" :class="{'transition ease-in duration-300 h-4 w-4 rounded cursor-pointer border-gray-300 text-purple-600 focus:ring-purple-500': true, 'cursor-not-allowed opacity-60': client.blocked, 'hover:border-gray-500': !client.blocked}"
                                    v-model="client.permissions.editingPermissions" :id="client.id" />
                                </div>
                            </td>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
                </div>
                <div v-if="systemList.length === 0" type="button" class="mt-6 mb-2 select-none relative block w-full border-2 border-purple-200 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto h-12 w-12 text-purple-300">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
                <span class="mt-2 block text-sm font-medium text-purple-300"> No users to manage </span>
                </div>
            <div class="flex justify-end mt-4">
            <button v-if="systemList.length > 0" :disabled="!comparePermissions" type="button" @click.prevent="submitPermissions()" class="transition ease-in duration-100 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500" :class="!comparePermissions ? 'opacity-50 cursor-not-allowed' : ''">Change permissions</button>
        </div>
    </div>
    
</template>
<script>
    // import Spinner  from '@/components/loadingSpinner.vue';
    import {db} from "@/firebase/gfbconf.js"
    import {UserIcon, LockClosedIcon} from '@heroicons/vue/solid';
    
    export default {
        name: "Permissions",
        components: {
           UserIcon,
           LockClosedIcon,
        },
        data: function () {
            return {
                askDelete: false,
                queryLimitReached: false,
                queryLimit: 10,
                search: {
                    active: false,
                    phrase: "",
                    found: [],
                    lastResult: null
                },
                lastLoaded: null,
                showAddModal: false,
                dbListener: null,
                systemList: [],
                edit: '',
                metaData: {},
                test: false,
                changes: false,
            }
        },
        computed: {
            comparePermissions() {
                let changes = false
                    this.systemList.forEach(user => {
                        if(JSON.stringify(user.permissions) !== JSON.stringify(user.oldPermissions)) {
                            changes = true
                        }
                    });
                return changes
            },
        },
        methods: {
            async loadSystemData() {
                this.systemList = [];
                for(let i = 0; i < this.$store.state.systemData.users.length;  i++) {
                    const result = await this.$store.state.systemData.users[i].ref.get();

                    if(!this.$store.state.systemData.users[i].creator) {
                        let user = result.data()
                        user.id = result.id
                        user.oldPermissions = JSON.parse(JSON.stringify(user.permissions))
                        this.systemList.push(user)
                    }
                    if(!this.$store.state.systemData.users[i].creator) {
                        for(let y=0; y<this.systemList.length; y++) {
                            if(this.systemList[y].id === this.$store.state.user.uid) {
                                this.systemList.splice(y, 1); 
                                y--; 
                            }
                        }
                    }
                   
                    
                }
                setTimeout(() => {
                    // console.log(this.systemList)
                    this.$store.commit("resetGlobalLoader");
                },200)
            },
            async submitPermissions()
            {
                try {
                    for(let i = 0; i < this.systemList.length; i++) {
                    this.systemList[i].oldPermissions = JSON.parse(JSON.stringify(this.systemList[i].permissions)) 
                    await db.collection('UsersData').doc(this.systemList[i].id).update({
                            permissions: this.systemList[i].permissions
                        });
                    this.$store.state.userData.permissions = this.systemList[i].permissions
                    }
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Permissions updated!",
                        subheader: `Permissions has been successfully updated.`,
                        success: true
                    });
                } catch(error) {
                    console.log(error.message);
                        this.$store.commit('setNotification',{
                        show: true,
                        head: "Error!",
                        subheader: `${error.message}`,
                        success: false
                    });
                }
            },
        },
        created()
        {
            this.$store.commit('setGlobalLoader',{
                show: true,
                head: "Loading user permissions..",
                subheader: `Do not close this tab.`,
            });
            this.loadSystemData()
        },
    };
</script>
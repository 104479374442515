<template>
    <div class="mt-2">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Create support ticket</h3>
        <p class="mt-1 text-sm text-gray-500">Report your problem or suggestion.</p>
    </div>
  <form class="space-y-6 mt-4" action="#" method="POST">
    <div class="bg-white">
      <div class="flex flex-col-reverse xl:grid xl:grid-cols-3 xl:gap-6">
        <div class="xl:col-span-2">
            <div class="transtion-all bg-white relative px-4 py-5 shadow rounded-lg sm:p-6 space-y-6 xl:col-span-1">
                
                <Transition name="fade"> 
                <div v-if="ticketSent" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"><div class="flex flex-col justify-center items-center"><BadgeCheckIcon class="h-16 w-16 text-green-400" aria-hidden="true"></BadgeCheckIcon><span class="mt-1 text-md text-green-500 font-medium">Ticket has been sent</span></div></div>
                </Transition>
                <div :class="{'grid grid-cols-3 gap-6 transition ease-in duration-200': true, 'opacity-0': ticketSent}">
                    <div class="col-span-3">
                    <label for="company-website" class="block text-sm font-medium text-gray-700">Type</label>
                    <div class="mt-1 flex rounded-md shadow-sm">
                        <Listbox :disabled="ticketSent" as="div" class="w-full" v-model="ticketType">
                            <div class="relative">
                                <ListboxButton class="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default transition ease-in duration-200 focus:outline-none focus:ring-1 focus:ring-purple-500 focus:border-purple-500 text-sm">
                                    <span class="flex items-center truncate">{{ticketType}}</span>
                                    <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                </ListboxButton>

                                <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
                                    <ListboxOptions class="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                    <ListboxOption v-for="type in types" :key="type" :value="type" as="template" v-slot="{ active, selected }">
                                        <li :class="[active ? 'text-white bg-purple-600' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                                        <span :class="[selected ? 'font-semibold' : 'font-normal', 'flex items-center truncate']">{{type}}</span>

                                        <span v-if="selected" :class="[active ? 'text-white' : 'text-purple-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                        </span>
                                        </li>
                                    </ListboxOption>
                                    </ListboxOptions>
                                </transition>
                            </div>
                        </Listbox>
                    </div>
                    </div>
                </div>

                <div :class="{'transition ease-in duration-200': true, 'opacity-0': ticketSent}">
                    <label for="topic" class="block text-sm font-medium text-gray-700">Topic<span class="text-red-500">*</span></label>
                    <div class="mt-1">
                        <input :disabled="ticketSent" type="text" id="topic" name="topic" v-model="topic" :class="{'shadow-sm focus:ring-purple-500 focus:border-purple-500 block w-full text-sm rounded-md transition ease-in duration-200': true, 'border-gray-300': true, 'border-red-600': this.errors.topicError }" />
                    </div>
                </div>

                <div :class="{'transition ease-in duration-200': true, 'opacity-0': ticketSent}">
                    <label for="message" class="block text-sm font-medium text-gray-700">Text<span class="text-red-500">*</span></label>
                    <div class="mt-1">
                    <textarea :disabled="ticketSent" v-model="message" id="message" name="message" rows="3" :class="{'block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring-purple-500 text-sm transition ease-in duration-200': true, 'border-red-600': this.errors.messageError}" />
                    </div>
                    <p class="mt-2 text-sm text-gray-500">Describe briefly your {{ticketType !== 'Suggestion' ? 'problem' : 'suggestion'}}.</p>
                </div>


                <div :class="{'transition ease-in duration-200': true, 'opacity-0': ticketSent}">
                    <label class="block text-sm font-medium text-gray-700 mb-1">Image</label>
                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40" v-show="imgPreviewSrc.length>0">
                        <img @click="triggerImgClick" :src="imgPreviewSrc" :class="{'object-center object-contain w-full group-hover:opacity-70 cursor-pointer': true, 'select-none pointer-events-none': ticketSent}">
                    </div>
                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                        <button type="button" @click.prevent="clearImg" class="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">Clear photo</button>
                    </div>
                    <div :class="{'transition ease-in duration-200 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none': true, 'select-none pointer-events-none': ticketSent}" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                <div class="space-y-1 text-center">
                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                    <label for="file-upload" :class="{'relative cursor-pointer bg-white rounded-md font-medium text-purple-600 hover:text-purple-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-purple-500': true, 'select-none pointer-events-none': ticketSent}">
                                    <span>Upload a file</span>
                                    <input :disabled="ticketSent" id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                    </label>
                                    <p class="pl-1">or drag and drop</p>
                                </div>
                                <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG up to 2 mb</p>
                                </div>
                            </div>
                </div>
                <div :class="{'flex justify-end transition ease-in duration-200': true,'opacity-0': ticketSent}">
                    <!-- <button type="button" class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2">Cancel</button> -->
                    <button :disabled="this.sendButtonLock || ticketSent" @click.prevent="sendTicket()" type="submit" :class="{'ml-3 inline-flex justify-center rounded-md border border-transparent bg-purple-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2': true, 'opacity-50 select-none pointer-events-none': sendButtonLock , 'select-none pointer-events-none': ticketSent}">Send ticket</button>
                </div>
            </div>
        </div>
        <div class="flex flex-col-reverse xl:block mb-4 xl:mb-0">
            <div class="bg-white px-4 py-5 mt-4 xl:mt-0 shadow rounded-lg sm:p-6">
                <label class="block text-sm font-medium text-gray-700">Sending ticket as:</label>
                <div class="mt-2 w-full flex items-center space-x-4">
                <div class="">
                    <img class="inline-block h-10 w-10 rounded-full" :src="$store.state.user.photoURL" alt="Support ticket on Complaia" />
                </div>
                <div class="flex flex-col">
                    <span class="text-sm truncate font-medium text-gray-700 group-hover:text-gray-900">{{$store.state.user.displayName.substring(0,25)}}{{$store.state.user.displayName.length > 25 ? '...' : ''}}</span>
                    <span class="text-xs truncate mt-0.5 font-medium text-gray-500 group-hover:text-gray-700">{{this.$store.state.user.email.substring(0,30)}}{{this.$store.state.user.email.length > 30 ? '...' : ''}}</span>
                </div>
                </div>
            </div>
            <!-- <div class="bg-white shadow rounded-lg xl:mt-4">
                <div class="text-sm text-gray-700 font-medium px-4 py-5 sm:px-6 sm:py-4">Frequently asked questions</div>
                <div v-for="(faq, index) in faq" :key="index" :id="`faq${index}`" ref="firstFAQ" class="goals-box expand relative first:mt-0 grid grid-cols-1 gap-y-4 gap-x-4 sm:grid-cols-6 border-t border-gray-200 px-4 sm:px-6 py-4">
                    <div class="col-span-6" ref="faqIndex">
                        <h4 class="text-xs font-medium leading-4 text-gray-900 pr-8">{{faq.question}}</h4>
                        <span class="absolute transform -translate-y-2.5 right-0 top-0 py-6 pr-4 sm:pr-6">
                            <img src="https://firebasestorage.googleapis.com/v0/b/complaia.appspot.com/o/webAssets%2Fexpand-button.svg?alt=media&token=539335a9-af3b-40c1-8dd8-e9658ebb4958" class="expand-icon rotated" @click.prevent="expand(index)">
                        </span>
                    </div>
                    
                    <div class="text-xs text-gray-700 col-span-6 select-none" ref="faqAnswer">{{faq.answer}}</div>
                </div>
            </div> -->
        </div>
      </div>
    </div>
  </form>
    <div>
        <div class="mt-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Your tickets</h3>
            <p class="mt-1 text-sm text-gray-500">Tickets sent from your account.</p>
        </div>
        <Spinner v-if="loading"></Spinner>
        <div v-if="!loading" class="mt-3 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap">Ticket type</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Topic</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Message</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Ticket ID</th>                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Creation date</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"><span>Status</span></th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="ticket in currentUserTickets" :key="ticket.ticketId">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div class="font-medium text-gray-900 truncate select-none">{{ticket.ticketType}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="font-medium text-gray-900 truncate">{{ticket.topic.substring(0,15)}}{{ticket.topic.length > 15 ? '...' : ''}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="font-medium text-gray-900 truncate">{{ticket.message.substring(0,25)}}{{ticket.message.length > 25 ? '...' : ''}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                        <div class="font-medium text-gray-700">{{ticket.ticketId}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                        <div class="font-medium text-gray-700">{{ticket.created.asText}}</div>
                                    </td>
                                    <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="flex items-center">
                                        <div class="h-10 w-10 flex-shrink-0">
                                            <img class="h-10 w-10 rounded-md object-contain" :src="ticket.imageLink !== null ? ticket.imageLink : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Ticket${ticket.ticketId}`)}.svg`" :alt="ticket.message" />
                                        </div>
                                        </div>
                                    </td> -->
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" v-if="ticket.resolved">Resolved</span>
                                        <span class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-600" v-if="!ticket.resolved">Pending</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="!loading && this.currentUserTickets.length === 0"></EmptyState>
    </div>
    <div v-if="this.$store.state.userData.systemCreator">
        <div class="mt-6">
            <h3 class="text-lg font-medium leading-6 text-gray-900">System tickets</h3>
            <p class="mt-1 text-sm text-gray-500">Tickets sent from your system.</p>
        </div>
        <Spinner v-if="loading"></Spinner>
        <div v-if="!loading" class="mt-3 flex flex-col pl-2 pr-4 sm:pl-0 sm:pr-6 xl:px-0">
            <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 align-middle sm:px-6 pl-2 pr-0.5 lg:px-8">
                    <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg" v-if="!loading">
                        <table class="min-w-full divide-y divide-gray-300">
                            <thead class="bg-gray-50">
                                <tr>
                                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 whitespace-nowrap">Ticket type</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Topic</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Message</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Ticket ID</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Created by</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap">Creation date</th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 whitespace-nowrap"><span>Status</span></th>
                                </tr>
                            </thead>
                            <tbody class="divide-y divide-gray-200 bg-white">
                                <tr v-for="ticket in currentSystemTickets" :key="ticket.ticketId">
                                    <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                        <div class="font-medium text-gray-900 truncate select-none">{{ticket.ticketType}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="font-medium text-gray-900 truncate">{{ticket.topic.substring(0,15)}}{{ticket.topic.length > 15 ? '...' : ''}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="font-medium text-gray-900 truncate">{{ticket.message.substring(0,25)}}{{ticket.message.length > 25 ? '...' : ''}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                        <div class="font-medium text-gray-700">{{ticket.ticketId}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                        <div class="font-medium text-gray-700">{{ticket.userName.substring(0,15)}}{{ticket.userName.length > 15 ? '...' : ''}}</div>
                                    </td>
                                    <td class="whitespace-nowrap px-3 py-4 text-xs text-gray-500 select-none">
                                        <div class="font-medium text-gray-700">{{ticket.created.asText}}</div>
                                    </td>
                                    <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <div class="flex items-center">
                                        <div class="h-10 w-10 flex-shrink-0">
                                            <img class="h-10 w-10 rounded-md object-contain" :src="ticket.imageLink !== null ? ticket.imageLink : `https://avatars.dicebear.com/api/initials/${encodeURIComponent(`Ticket${ticket.ticketId}`)}.svg`" :alt="ticket.message" />
                                        </div>
                                        </div>
                                    </td> -->
                                    <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 select-none">
                                        <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800" v-if="ticket.resolved">Resolved</span>
                                        <span class="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-600" v-if="!ticket.resolved">Pending</span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        
                    </div>
                </div>
            </div>
        </div>
        <EmptyState class="mt-2 mb-2" v-if="!loading && this.currentSystemTickets.length === 0"></EmptyState>
    </div>
</template>
<script>
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import {CheckIcon, BadgeCheckIcon} from '@heroicons/vue/outline';
import {SelectorIcon} from "@heroicons/vue/solid"
import {storage} from "@/firebase/gfbconf.js";
import EmptyState from '../components/EmptyState.vue';
import Spinner  from '@/components/loadingSpinner.vue';
import axios from 'axios';
    export default {
        name: "Support",
        components: {
            Listbox,
            ListboxButton,
            ListboxOption,
            ListboxOptions,
            CheckIcon,
            SelectorIcon,
            EmptyState,
            Spinner,
            BadgeCheckIcon
        },
        data: function () {
            return {
                ticketSent: false,
                sendButtonLock: false,
                fileLimitSize: 2097152,
                imgPreviewSrc: "",
                types: [
                    'Problem with system',
                    'Bug found',
                    'Need help',
                    'Suggestion'
                ],
                ticketType: 'Problem with system',
                topic: '',
                message: '',
                currentSystemTickets: [],
                currentUserTickets: [],
                loading: true,
                errors:{
                    topicError: false,
                    messageError: false,
                },
                faq: [
                    {
                        question: "My question is...?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam imperdiet ullamcorper tincidunt.",
                        expanded: false
                    },
                    {
                        question: "My question is blah blah blah...?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        expanded: false
                    },
                    {
                        question: "My question is blah blah...?",
                        answer: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
                        expanded: false
                    }
                ],
            }
        },
        methods: {
            expand(button){
                for (let i = 0; i < this.faq.length; i++) {
                    if(this.faq[i].expanded === true && button !== i){
                        document.querySelectorAll('.goals-box')[i].classList.toggle('expand');
                        document.querySelectorAll('.expand-icon')[i].classList.toggle('rotated');
                        this.faq[i].expanded = false
                    }
                }
                this.faq[button].expanded = !this.faq[button].expanded
                document.querySelectorAll('.goals-box')[button].classList.toggle('expand');
                document.querySelectorAll('.expand-icon')[button].classList.toggle('rotated');
            },
            async downloadTickets(){
                this.loading = true
                const tickets = await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/support/downloadTickets", 
                {
                    systemId: this.$store.state.userData.system.id,
                    userId: this.$store.state.user.uid,
                    systemCreator: this.$store.state.userData.systemCreator
                }
                ).catch((error)=>
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Can't download your tickets.`,
                            success: false
                        });
                        this.loading = false
                    });
                if(tickets.data.status === "ok"){
                    if(this.$store.state.userData.systemCreator){
                    this.currentSystemTickets = tickets.data.systemTickets
                    }
                    this.currentUserTickets = tickets.data.userTickets
                    this.loading = false
                }else{
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Oops! Something went wrong!",
                        subheader: `Can't download your tickets.`,
                        success: false
                    });
                    this.loading = false
                }
            },
            async sendTicket(){
                // Lock button
                this.sendButtonLock = true

                let errors = 0
                if(this.topic.length > 0){
                    this.errors.topicError = false
                }else{
                    this.errors.topicError = true
                    errors++
                }

                if(this.message.length > 0){
                    this.errors.messageError = false
                }else{
                    this.errors.messageError = true
                    errors++
                }
                // Preparing ticket
                const preparedTicket = {
                    imageLink: null,
                    imageRef: null,
                    ticketType: this.ticketType,
                    topic: this.topic,
                    message: this.message,
                    resolved: false,
                    systemId: this.$store.state.userData.system.id,
                    userName: this.$store.state.user.displayName,
                    userEmail: this.$store.state.user.email,
                    userId: this.$store.state.user.uid,
                }

                if(errors === 0){
                    try
                    {
                        // Upload an image & assign image to ticket
                        if(this.$refs.mainImgUploader.files.length>0)
                        {
                            const storageRef = storage.ref();
                            const filename = this.filenameGenerator(10);
                            const path = `supportImages/Systems/${this.$store.state.userData.system.id}/${filename}`;
                            const imageRef = storageRef.child(path);
                            await imageRef.put(this.$refs.mainImgUploader.files[0]);
                            const fileUrl = await imageRef.getDownloadURL();
                            preparedTicket.imageLink = fileUrl;
                            preparedTicket.imageRef = path;
                        }
                        
                        await axios.post("https://europe-central2-complaia.cloudfunctions.net/api/support/addTicket", preparedTicket).catch((error)=>
                            {
                            console.log(error);
                        });


                        // Reset data
                        this.ticketType = 'Problem with system',
                        this.topic = '',
                        this.message = ''
                        this.clearImg()

                        // Notification of success
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Ticket sent!",
                            subheader: `Thank you for your report. We will reply as soon as possible.`,
                            success: true
                        });

                        this.downloadTickets()
                        // Unlock button
                        this.sendButtonLock = false
                        
                        this.ticketSent = true
                    }
                    catch(error)
                    {
                        console.log(error);
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "Oops! Something went wrong!",
                            subheader: `Cannot send ticket.`,
                            success: true
                        });  
                    }
                }else{
                    this.sendButtonLock = false
                }
            },
            filenameGenerator(length)
            {
                let result           = `sys_${this.$store.state.userData.system.id}_ticket_`;
                let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                let charactersLength = characters.length;
                for ( let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * 
                    charactersLength));
                }
                return result;
            },
            triggerImgClick()
            {
                this.$refs.mainImgUploader.click();
            },
            setMainImage()
            {
                if(this.$refs.mainImgUploader.files.length>0)
                {
                    if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
                    {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                        fileReader.addEventListener("load", () =>
                        {
                            this.imgPreviewSrc = fileReader.result;
                        })
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "File is more than 2MB",
                            subheader: `Image is too large.`,
                            success: false
                        });                    
                    }
                }
            },
            clearImg()
            {
                this.imgPreviewSrc = '';
                this.$refs.mainImgUploader.value = "";
            },
            dropFile(e)
            {
                if(e.dataTransfer.files.length>0)
                {
                    if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
                    {
                        if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                        {
                            const fileReader = new FileReader();
                            fileReader.readAsDataURL(e.dataTransfer.files[0]);
                            fileReader.addEventListener("load", () =>
                            {
                                this.imgPreviewSrc = fileReader.result;
                            })
                        }
                        else
                        {
                            this.$store.commit('setNotification',{
                                show: true,
                                head: "File is more than 2MB",
                                subheader: `Image is too large.`,
                                success: false
                            }); 
                        }
                    }
                    else
                    {
                        this.$store.commit('setNotification',{
                            show: true,
                            head: "File is not image.",
                            subheader: `File should be image type.`,
                            success: false
                        });   
                    }
                }
                
            },
        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
            this.downloadTickets()
        }
    };
</script>
<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }

    .goals-box {
    max-height: 8rem;
    overflow: hidden;
    transition: all 0.5s ease;
    }

    .goals-box.expand {
        max-height: 3rem;
    }
    .expand-icon {
        width: 1.25rem;
        height: 1.25rem;
        transition: transform .3s ease-in-out;
    }
    .expand-icon:hover {
        cursor: pointer;
        opacity: 0.5 !important;
    }
    .expand-icon.rotated {
        transform: rotate(180deg);
    }
</style>
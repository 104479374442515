<template>
  <div type="button" class="select-none relative block w-full border-2 border-purple-200 border-dashed rounded-lg p-12 text-center focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
    <SparklesIcon class="mx-auto h-12 w-12 text-purple-200"></SparklesIcon>
    <span class="mt-2 block text-sm font-medium text-purple-300"> Nothing here.. </span>
  </div>
</template>
<script>
    import { SparklesIcon} from '@heroicons/vue/outline';
    export default {
        name: "EmptyState",
        components: {SparklesIcon},
        data: function () {
            return {
            }
        }
    }
</script>
<template>
    <div id="analytics" class="mt-2">
        <Stats></Stats>
    </div>
</template>
<script>
    import Stats from "@/components/Stats";
    export default {
        name: "Analytics",
        components: {Stats},
        data: function () {
            return {
                
            }
        },
        methods: {

        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
            
        }
    };
</script>
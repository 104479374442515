<template>
    <div id="feed" class="mt-2">
        <TodayStats></TodayStats>
        <div class="flex justify-between border-b pb-2 pt-2">
            <span class="mt-3 text-lg font-medium text-gray-900 flex justify-center">Last events</span>
            <span v-if="this.$store.state.userData.permissions.advancedFeed" class="mt-3 text-sm text-purple-600 hover:text-purple-800 cursor-pointer flex justify-center items-center" @click="this.$router.push({ path: '/dashboard/advanced' })">Check all events</span>
        </div>
        <LastActions/>
    </div>
</template>
<script>

    import LastActions from '@/components/LastActions';
    import TodayStats from "@/components/todayStats";
    export default {
        name: "Feed",
        components: {TodayStats, LastActions},
        data: function () {
            return {
                
            }
        },
        methods: {

        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
        }
    };
</script>
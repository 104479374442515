<template>
  <div>
    <ul role="list" class="divide-y divide-gray-200" v-if="eventList.length>0">
      <li v-for="(event,indx) in eventList" :key="indx" class="py-4">
        <div class="flex space-x-3">
          <img class="h-6 w-6 rounded-full" :src="event.user.photo" alt="Event" />
          <div class="flex-1 space-y-1">
            <div class="flex items-center justify-between">
              <h3 class="text-sm font-medium">{{ event.user.name }}</h3>
              <p class="text-sm text-gray-500">{{event.fromNow}}</p>
            </div>
            <p class="text-sm text-gray-500" >{{event.eventName}}</p>
          </div>
        </div>
      </li>
    </ul>
    
  </div>
  <emptyState class="mt-2" v-if="eventList.length === 0"></emptyState>
</template>

<script>

import moment from 'moment';
import emptyState from '@/components/EmptyState.vue'
export default {
  data: function () {
      return {
        dbListener: null,
        eventList: []
      }
  },
  components: {emptyState},
  created()
  {
    this.dbListener = this.$store.state.userData.system.ref.collection("Events").orderBy("creationDate", "desc").limit(10)
    .onSnapshot((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.fromNow = moment(data.creationDate.seconds*1000).fromNow();
          this.eventList.push(data);
        });
    });
  },
  beforeUnmount() {
    this.dbListener();
  }
}
</script>
<template>
    <div>
        <chatsList></chatsList>
    </div>
</template>

<script>
import chatsList  from '@/components/chatsList.vue';
    export default {
        name: "chatInbox",
        components: {chatsList},
        data(){
            return {
            }
        },
        methods: {
          
        },
        computed:{

        },
        mounted()
        {
        },
        created()
        {
          
        }
    };
</script>